body{
    background-image: linear-gradient(to right, #ffffff, #8bdbdbe0, #ffffff);
}

#bigMap {
    width: 100%;
    height: 90vh;
}

.buttonRight {
    text-align:right; 
    width:100%; 
    padding:0
}

.buttonLeft {
    text-align:left; 
    width:100%; 
    padding:0
}

.disabledButton {
    cursor: not-allowed;
}

.badge{
 float: right;
 margin-right: 5px;
}

.closeX{
    float: right;
    margin-top: 10px;
    margin-right: 10px;
}

.checks {
    margin-bottom: 10px;
    padding: 15px;
    background-color:rgba(125, 127, 128, 0.06);
}

/* Modals */

.modalHeader {
    background-color:rgba(43, 175, 17);
    color: white;
}

button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modalBg{
    background-color:rgba(12, 212, 226, 0.199);
}

.markHeader {
    background-color:rgba(12, 212, 226, 0.966);
    color: white;
}

/*Mark card*/
.markCard{
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cardGroups{
    float: left;
}

.cardTime{
    font-size:16px;
    float: right
}

.cardText{
    background-color:rgba(12, 212, 226, 0.199);
    padding: 15px;
    margin-bottom: 15px;
}

.cardImage{
    display: block;
    max-width: 100%;
    height: auto;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.imginfo{
    max-width: 450px;
    background-color:rgba(12, 212, 226, 0.199);
    margin: "auto"
}

.imgMark{
    max-width: 550px;
    margin: "auto";
}

.logoBg {
    background-color: white;
    padding: 4px;
    float: right;
}

/* Navigation */

.navLink {
    margin-left: 5px;
    margin-right: 5px;
}

.navLink:hover {
    background-color: #f0efef;
    border-radius: 10px;
}

/* change the color and bg color of active links */
.nav-link.active {
    background-color: #dddcdc;
    border-radius: 10px;
}

.textShow {
    color: rgb(43, 175, 17);
    font-weight: bold;
}

.logo {
    margin-right: 10px;
    background-color: white;
}

/* in MarkCard */
.groupIcon{
    width: 25px;
    margin-right: 10px;
}

/* in SearchForm & TypeForm */
.searchGroup{
    margin-bottom: 20px;
    padding-left: 20px;
}

/* in TypeForm */
.typeRadio{
    max-width: 300px;
}

/* in Crud */

#crudMap {
    width: 100%;
    height: 400px;
}

.crudMap{
    border: 1px solid gray;
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
}

.crudButton{
    width: 250px;
    margin: 5px;
}

.crudCard{
    width: 300px;
    margin:10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.crudImageCard{
    width: 300px;
    margin:10px;
}

.crudImage{
    height: 200px;

}

.crudH2{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    text-shadow: 1px 2px 3px black;
}

.crudPage{
    max-width: 900px;
    margin: auto;
    background-color:rgb(219, 217, 217);
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top:20px;
}

.CrudBox {
    max-width: 880px;
    padding: 40px;
    margin: auto;
    border: 2px solid rgb(184, 244, 248);
    border-radius: 5px;
    background-color: white;
}

.CrudBox2 {
    max-width: 880px;
    padding: 5px;
    margin: auto;
    border: 2px solid rgb(184, 244, 248);
    border-radius: 5px;
    background-color: white;
}

/*----------------------------*/

.editModal{
    background-color:rgba(12, 212, 226, 0.199); 
    padding: 10px;
}

.editFooter {
    background-color:rgba(12, 212, 226, 0.199); 
}

.dropButton{
    box-shadow: 0 0 0;
    padding: 10px;
}

.dropdown{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 60px;
    display: inline-block;
}



.spinnerCard {
    max-width: 70%;
    margin: auto;
    margin-top: 50px;
}

.searchCheck{
    width: 100px;
}

.searchTime{
    width: 200px;
}

.hiddenElement{
    display: none
}

.imgSearch{
    display: inline;
    width: 25px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.infoParagraph{
    padding: 20px;
}

.writePlace {
    width: 160px;
}

/*.alert {
    z-index: 100;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 300px;
}

.alertHeader {
    color: white;
    background-color: darkgreen;
}*/

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

/*crud Images*/
.imgDelete {
    width: 100%;
    height: 100%;
    background-color: rgb(182, 24, 24);
    color: white;
    padding: 10px;
}

.imgDelete:hover {
    background-color: red;
} 

.imgAdd {
    width: 100%;
    height: 100%;
    background-color: rgb(24, 182, 32);
    color: white;
    padding: 10px;
}

.imgAdd:hover {
    background-color: green;
} 


.imageCard {
    max-width: 250px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;
    border: solid 1px gray;
    display: inline-block;
}

.imgPreview {
    width: 200px;
    margin: 5px;
}

.deleteDialogHeader{
    background-color: rgb(138, 133, 133);
    color: white;
}

.textSaved {
    max-width: 300px;
    display: inline;
    color: black;
    background-color: rgb(203, 236, 146);
    margin: 5px;
    box-shadow: 0 0 0;
}

.dialogUnsaved{
    background-color: rgb(216, 30, 30);
    color: white;
}

.dialogSaved{
    background-color: green;
    color: white;
}

.dialogWarning{
    background-color: rgba(255, 255, 0);
    color: black;
}

.backMapLink {
    background-color: #686868;
    color: white;
    border-radius: 5px;
}

.backMapLink:hover {
    background-color: rgb(37, 37, 37);
}

.infoBox {
    border: 1px solid #686868 ;
    padding: 10px;
}


/* summary */
.firstColumn {
    min-width: 180px;
}

.tableSummary {
    width: 600px;
}

@page {
    size: auto;
    margin: 10mm;
}

/*checks*/
.checkH6{
    text-decoration: underline;
}

/*login*/
.loginForm{
    border: 1px solid #686868 ;
    margin-left: 10%;
    margin-right: 10%;
    padding: 5%;
    min-width: 200px;
}

.linkButton{
    background: none;
    border: none;
    padding: 0;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    box-shadow: none;
}

.linkButton2{
    background: none;
    border: none;
    padding: 0;
    margin-top: -5px;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    box-shadow: none;
}

.bgRegister{
    background-color: lightslategray;
    border: 1px solid black;
    padding:15px;
}

.settings {
    background-color: #ececec;
    color: white;
    border-radius: 5px;
    width: 90px;
    margin-left: 10px;
}

.settings:hover {
    background-color: #dfdede;
}

.loginUser {
    color:rgba(0, 0, 0, 0.2);
}

.mapInfo2{
    width: 100%;
    font-size: medium;
    /*background-color: #aad3df46;*/
    text-align: center;
    padding: 5px; /*top, right/left, bottom*/
}

.mapInfoText2{
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 1);
    border-radius: 25px;
}

.mapInfoButton{
    font-size: 10px;
    padding: 1px;
    border-radius: 100%;
    background-color: rgb(103, 169, 202);
    margin-left: 10px;
    box-shadow: none;
}

.mapInfoText{
    border: 2px solid black;
    padding: 5px;
}

.redBorder{
    border: 1px solid red;
}

@media only screen and (max-width: 350px) {
    .crudCard{
        width: 150px;
        margin:5px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
    }
    .crudButton{
        width: 100px;
        margin: 5px;
    }
    .crudImageCard{
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .crudCard{
        width: 200px;
        margin:5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    }
    .crudButton{
        width: 150px;
        margin: 5px;
    }
}

/*@media only screen and (max-width: 785px) {
    .crudCard{
        width: 275px;
        margin:5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    }
    .crudButton{
        width: 225px;
        margin: 5px;
    }
} */

@media only screen and (max-width: 992px) {
    .settings {
        background-color: transparent;
        color: grey;
        margin-left: 5px;
    }
}